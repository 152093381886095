import { useScripts } from '@Shared/hooks/useScripts';
import useMobx from '@Shared/hooks/useMobx';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useScriptPermission } from '@Shared/components/gdpr/hooks/useScriptPermission';

function AnalyticScripts() {
  const { content, i18n, settings } = useMobx();
  const { zendesk, exponea, ga } = useScripts();
  const allowed = useScriptPermission('analytics');

  useEffect(() => {
    if (window.$zopim && window.$zopim.livechat) {
      window.$zopim.livechat.setLanguage(i18n.locale);
    }
  }, [i18n.locale]);

  if (content.meta.isCookieConsentEnabled && !settings.isTurnitApp) {
    if (!allowed) {
      return null;
    }
  }

  return (
    <Helmet>
      {zendesk({
        isEnabled: !!content.scripts.zendesk_script_token,
        token: content.scripts.zendesk_script_token,
        locale: i18n.locale,
      })}
      {exponea({ isEnabled: !!content.scripts.exponea_script_token, token: content.scripts.exponea_script_token })}
      {ga({
        isEnabled: !!content.scripts.google_analytics_script_token,
        token: content.scripts.google_analytics_script_token,
      })}
    </Helmet>
  );
}

export default observer(AnalyticScripts);
